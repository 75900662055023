import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import NavMain from "./nav/navMain"
import NavMinimal from "./nav/navMinimal"
// import NavNone from './navNone'
// import NavApp from './navApp'
import Footer from "./nav/footer"
import FooterMinimal from "./nav/footerMinimal"
import FooterMinimalSticky from "./nav/footerMinimalSticky"
// import PrefooterChallenge from './PrefooterChallenge'
// import './layout.css'

import favicon from "../images/piconft-icon.png"
import parse from "url-parse"

import "./../scss/main.scss"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageContext: "",
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    console.log("((((url))))")
    console.log(url)
    if (url.pathname) {
      const split = url.pathname.split("/")
      console.log("((((split))))")
      console.log(split)
      if (split[1] === "about" && split[2] === "terms") {
        this.setState({ pageContext: "legal" })
      } else if (split[1] === "about" && split[2] === "privacy") {
        this.setState({ pageContext: "legal" })
      } else if (url.pathname === "/") {
        this.setState({
          pageContext: "home",
        })
      } else {
        this.setState({ pageContext: split[1] })
      }
    }
  }

  render() {
    const { children } = this.props

    console.log("this.state.pageContext")
    console.log(this.state.pageContext)
    return (
      <div class="min-vh-100 d-flex flex-column">
        <Helmet
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
          ]}
        />

        {(() => {
          switch (this.state.pageContext) {
            // case "about":
            // case "home":
            //   return <NavMain />
            // case "app":
            // case "legal":
            // case "request-demo":
            //   return <NavMinimal />
            default:
              return <NavMinimal />
          }
        })()}
        {children}

        {(() => {
          switch (this.state.pageContext) {
            // case "about":
            // case "home":
            //   return (
            //     <div>
            //       <Footer />
            //     </div>
            //   )
            case "app":
              // case "legal":
              return <FooterMinimal />
            // case "request-demo":
            //   return <Footer />
            default:
              return <FooterMinimalSticky />
          }
        })()}
      </div>
    )
  }
}

export default IndexPage
