// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import { Link } from "gatsby"
import Cache from "@aws-amplify/cache"

import "./../../scss/main.scss"

//Assets
import Logo from "../../images/svg/piconft-logo-04.svg"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  render() {
    let cid = Cache.getItem("cid")

    return (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <Link class="navbar-brand" activeClassName="navbar-brand" to="/">
            <Logo />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="navbar-toggler-icon" />
          </button>
          <div class="collapse navbar-collapse navbar-main" id="navbarText">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <Link
                  to="/about/"
                  class="nav-link"
                  activeClassName="nav-link active"
                >
                  About
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/about/features/"
                  class="nav-link"
                  activeClassName="nav-link active"
                >
                  Features
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/about/use-cases/"
                  class="nav-link"
                  activeClassName="nav-link active"
                >
                  Use cases
                </Link>
              </li>
              {/* <li class="nav-item">
              <Link
                to="/about/pricing/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Pricing
              </Link>
            </li> */}
            </ul>
            <span class="navbar-text">
              <Link
                to="/request-demo/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                <div class="btn btn-primary btn-cta">Request a demo</div>
              </Link>
            </span>
          </div>
        </div>
      </nav>
    )
  }
}
