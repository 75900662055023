// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import { Link } from "gatsby"

import "./../../scss/main.scss"

export default class Nav extends React.Component {
  render() {
    return (
      <footer class="footer">
        <div class="container">
          <div class="row row-copyright d-flex flex-row justify-content-center align-items-center text-center">
            <span>&copy; 2022 Picovideo</span>
            <a
              href={`${process.env.MARKETING_DOMAIN}about/terms/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <a
              href={`${process.env.MARKETING_DOMAIN}about/privacy/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </div>
        </div>
      </footer>
    )
  }
}
